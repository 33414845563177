import { AUTH_SERVICE } from "@/api/auth";
import { AXIOS, setAxiosToken } from "@/plugin/axios";
import LS from "@/plugin/localstorage";

export default {
	namespaced: true,
	state: {
		user: {
			token: LS.get("user.token") || false,
			phone: LS.get("user.phone") || false,
			id: LS.get("user.id") || false,
			roles: LS.get("user.roles") || [] || undefined,
			phoneInfo: false,
			smsLog: LS.get("user.smslog") || [],
			submit: "",
		},
		practices: LS.get("practices") || null,
		verify: null,
	},
	getters: {
		userToken(state) {
			return state.user.token;
		},

		isOfficial(state) {
			let roles = state.user.roles;

			if (roles) {
				let string = roles.join("").toLowerCase();
				if (string.indexOf("official") !== -1) return true;
				return false;
			}
		},

		isPrivacyOfficial(state) {
			let roles = state.user.roles;

			if (roles) {
				let string = roles.join("").toLowerCase();
				if (string.indexOf("official") !== -1 && string.indexOf("privacy") !== -1) return true;
				return false;
			}
		},

		isSecurityOfficial(state) {
			let roles = state.user.roles;

			if (roles) {
				let string = roles.join("").toLowerCase();
				if (string.indexOf("official") !== -1 && string.indexOf("security") !== -1) return true;
				return false;
			}
		},

		isLoggedIn: state => {
			if (state.user.token) {
				return true;
			}
			return false;
		},

		getPractices(state) {
			if (state.practices) {
				return state.practices;
			}
		},

		getName(state) {
			if (state.practices) {
				return state.practices;
			}
		},

		getVerify(state) {
			if (state.verify) {
				return state.verify;
			}
		},

		userHireDate(state, getters) {
			let practice = getters.userPractice[0][0] || getters.userPractice[0];
			return practice.client.hireDate;
		},

		userVerificationDate(state, getters) {
			let practice = getters.userPractice[0][0] || getters.userPractice[0];
			return practice.client.VerifiedDate;
		},

		userPractice: state => {
			if (state.practices?.length === 1) return state.practices[0];
			return state.practices?.find(el => el[0].id == state.user.id);
		},
	},

	mutations: {
		setVerify(state, data) {
			state.verify = data;
		},

		clearToken(state) {
			state.user.token = undefined;
		},

		setUserToken(state, data) {
			state.user.token = data;
			LS.set("user.token", data);
		},

		setUserPhone(state, data) {
			state.user.phone = data;
		},

		setUserPhoneInfo(state, data) {
			state.user.phoneInfo = data;
		},

		setUserSmsLog(state, data) {
			state.user.smsLog = data;
			LS.set("user.smslog", data);
		},

		setUserId(state, data) {
			state.user.id = data;
			LS.set("user.id", data);
		},

		setPractices(state, data) {
			state.practices = data;
			LS.set("practices", data);
		},

		setHireDate(state, date) {
			state.user.hireDate = date;
			LS.set("hireDate", date);
		},

		setVerificationDate(state, date) {
			state.user.verificationDate = date;
			LS.set("verificationDate", date);
		},

		setUserPhoneToLs(state) {
			LS.set("user.phone", state.user.phone);
		},

		setRoles(state, roles) {
			state.user.roles = roles;
			LS.set("user.roles", roles);
		},
	},
	actions: {
		async checkPhone({ commit }, payload) {
			commit("loading", true, { root: true });
			const { response, status } = await AUTH_SERVICE.login(payload);
			if (!status) {
				commit("loading", false, { root: true });
				return response.data.message;
			}
			commit("loading", false, { root: true });
			commit("setUserPhone", response.data.phone);

			return status;
		},

		async loginCodeVerification({ commit }, payload) {
			commit("loading", true, { root: true });
			const { response, status } = await AUTH_SERVICE.loginVerification(payload);
			if (!status) {
				commit("loading", false, { root: true });
				return response.data.message;
			}
			commit("setUserPhoneInfo", response.data[0].phone_info);
			commit("setUserSmsLog", response.data[0].sms_log);

			commit("setUserPhoneToLs");
			commit("loading", false, { root: true });
			return status;
		},

		async askForToken({ commit, dispatch, getters }, payload) {
			commit("loading", true, { root: true });

			const { response, status } = await AUTH_SERVICE.checkForPractice(payload);

			if (!status) {
				commit("loading", false, { root: true });
				return response.data.message;
			}

			commit("setPractices", response.data.practices);
			commit("setVerify", response.data.verify);

			if (response.data?.roles.length > 0) {
				commit("setRoles", response.data.roles);
			}

			if (response.data.token) {
				const token = response.data.token;
				let id = payload.sms_log[0];
				if (payload.submit) id = payload.submit;
				return { token, id };
			}

			commit("loading", false, { root: true });
			return false;
		},
	},
};
